import React, { Component } from 'react'
import './Titleimage.css'

export class Titleimage extends Component {
  render() {
    return (
      <div className='hero-img'>
          <div className='heading'>
              <h1>{this.props.heading}</h1>
              <p>{this.props.text}</p>


          </div>

      </div>
    )
  }
}

export default Titleimage