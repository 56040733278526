
import './Cards1.css';
import CardItem1 from './CardItem1';

//import { Button } from './Button';


import React from 'react';




function Cards1() {


  






  return (
   
      




<div className='cards1'>
      <h1>Únete a HWASA</h1>
      <p>HWASA te invita a unirte a nosotras y ser parte de esta increíble Asociación de mujeres que empodera a las mujeres. Explora las formas en que puedes ser parte de nuestra comunidad.</p>
      






      
      <div className='cards1__container'>
        <div className='cards1__wrapper'>
          <ul className='cards1__items'>
           
            <CardItem1
      
     src='images/member.jpg'
     text='Solicitud de Membresía'
                        
            
     path='/unete-a-hwasa-socia'

     
             
                

             
/>


           
          


         
         
            <CardItem1
                       src='images/volunteer.jpg'
              text='Solicitud de Voluntariado'
          
              path='/unete-a-hwasa-voluntaria'
              
            />

          </ul>


        
         
        </div>
      </div>


     
    </div>

  );
}

export default Cards1;
