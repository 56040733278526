import React from 'react';
import './App.css';
import Home from './components/pages/Home';
import { BrowserRouter as Switch, Route, useLocation } from 'react-router-dom';
import Services from './components/pages/Services';
import AboutUs from './components/pages/AboutUs'; 
/* import Events from './components/pages/Events'; */
import Socia from './components/pages/Socia';
import Voluntaria from './components/pages/Voluntaria';
import Projects from './components/pages/Projects';
import Familia from './components/pages/Familia';

import Disfruta from './components/pages/Disfruta';
//import Historia from './components/pages/Historia';
//import Mision from './components/pages/Mision';
//import Vision from './components/pages/Vision';
//import NuestroEquipo from './components/pages/NuestroEquipo';
import Menu from './components/Menu';
import { AnimatePresence} from 'framer-motion';
import Boletin from './components/pages/Boletin';
import Footer from './components/Footer';
import UneteaHwasa from './components/pages/UneteaHwasa';
import ContactUs from './components/pages/ContactUs'
import styled from 'styled-components';
import GlobalStyle from './globalStyle';
import Orientación from './components/pages/Orientacion';
import 'bootstrap/dist/css/bootstrap.min.css';
import Laborales from './components/pages/Laborales';
import {Helmet} from "react-helmet";



const Section = styled.section`
overflow-x: hidden;
`;


function App() {
  const location = useLocation();

  return (
    <Section>
    <Helmet>
                <meta charSet="utf-8" />
                <title>Hispanic Women's Association of South Australia</title>
                <link rel="canonical" href="https://hwasa.org.au/" />
                <meta name="description" content="Hispanic Women's Association of South Australia" />
            </Helmet>
<Menu />

<GlobalStyle />
        <AnimatePresence exitBeforeEnter>
        <Switch location={location} key={location.pathname}>
          <Route path='/' exact component={Home} />
          <Route path='/quiénes somos' exact component={AboutUs} />
          {/*<Route path='/sobre-nosotras-historia' exact component={Historia} />
          <Route path='/sobre-nosotras-mision' exact component={Mision} />
          <Route path='/sobre-nosotras-vision' exact component={Vision} />
          <Route path='/sobre-nosotras-nuestro-equipo' exact component={NuestroEquipo} />
           <Route path='/eventos' exact component={Events} /> */}
          <Route path='/áreas de enfoque' exact component={Services} />
          <Route path='/programas' exact component={Projects} />
          <Route path='/programas-la-familia' exact component={Familia} />
          <Route path='/programas-disfruta-en-línea' exact component={Disfruta}/>
          <Route path='/programas-orientación-información' exact component={Orientación} />
          <Route path='/programas-oportunidades-laborales' exact component={Laborales} />
         
          <Route path='/boletin' exact component={Boletin} />
          <Route path='/unete-a-hwasa' exact component={UneteaHwasa} />
          <Route path='/unete-a-hwasa-socia' exact component={Socia} />
          <Route path='/unete-a-hwasa-voluntaria' exact component={Voluntaria} />
          <Route path='/contáctanos' exact component={ContactUs} />
          
<Footer/>
   
        </Switch>
       

      
        </AnimatePresence>
        </Section>


 

    
   
      
      
    
  );
}

export default App;

