
import '../../App.css';
import Titleimage from '../Titleimage';
import './Historia.css';


import React from 'react';


  function AboutUs() {
 
  
    
    
    return (
   
  <div>
  <Titleimage heading='Quiénes Somos' />
  
  
  
  <div className='historia'>
  
  {/*<div className='left'>
  <div className='img-container'>
  <img src='/images/img16.jpg' alt='' className='historia-me'loading='auto'/>  
  
  </div>
     
  </div> */}
   
     
    <div className='right'>
  
       <h2>Quiénes Somos</h2>
       <p>
       Hwasa es una asociación de mujeres hispanas sin afiliación política y discriminación, establecida sin fines de lucro para representar y promover los intereses de las mujeres y sus familias en la ciudad de Adelaide y el Sur de Australia.
  Constituida en Julio de 2019 por un grupo de diez mujeres de diversos países hispanos cuyo objetivo principal es atender las necesidades de las mujeres y sus familias, buscando enriquecer sus vidas, para desarrollar al máximo su potencial personal, social y profesional. 
  
       </p>
       <h2>Nuestros Valores:</h2>
       <ul>
       <li>•	Acceso a la equidad </li>
     <li>•	Inclusión y diversidad</li>
     <li>•	Autodeterminación</li>
     <li>•	Empoderamiento</li>
     <li>•	Esperanza</li>


       </ul>
  





     
       <h2>Misión</h2>
     <p>
     Apoyar a las mujeres de origen hispano y sus familias mediante el desarrollo de una variedad
    de eventos y actividades que satisfagan sus necesidades sociales, culturales, educativas y laborales.
     </p>
      
     <h2>Visión</h2>
     <p>
     Todas las mujeres de origen hispano y sus familias en Australia del Sur aumentan su nivel de participación en la sociedad.
     </p>
       
  
       </div>
       </div>
      
        


    

     

  
  </div>
  
  
       
  
     
   
   
       
  
    
    );
  }
  
  
  export default AboutUs;
  





