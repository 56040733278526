
import '../../App.css';
import './Events.css';
import Titleimage from '../Titleimage';
import React from 'react';
import './Historia.css';

function Services() {

  
  
  
  return (
    <div>
    <Titleimage heading='Áreas de Enfoque' />
    
    
    
    <div className='historia'>
    
    {/*<div className='left'>
    <div className='img-container'>
    <img src='/images/img16.jpg' alt='' className='historia-me'loading='auto'/>  
    
    </div>
       
    </div> */}
     
       
      <div className='right'>
    
         <h2>Áreas de Enfoque</h2>
         <ul>
         <li>•	Información y acompañamiento</li>         
          <li>•	Educación</li>
        <li>•	Trabajo</li>
        <li>•	Salud y bienestar (personas mayores y salud mental)</li>
        <li>•	Conexión social (festivales, arte y actividades lúdicas)</li>
        <li>•	Empoderamiento a la mujer en la toma de decisiones</li>
        <li>•	Asesoramiento financiero </li>
        <li>•	Grupo intergeneracional</li>
        <li>•	Desarrollo y participación social</li>  
  
         </ul>
      
         <h2>Trabajamos en colaboración con:</h2>
         <ul>
         <li>•	Organizaciones comunitarias </li>
       <li>•	Organizaciones gubernamentales y no gubernamentales</li>
       <li>•	Grupos religiosos</li>
       <li>•	Corporaciones privadas</li>
       <li>•	Otros</li>
  
  
         </ul>
    
  
  
  
  
  
       
      
         
    
         </div>
         </div>
        
          
  
  
      
  
       
  
    
    </div>
    


  )

}

export default Services;
