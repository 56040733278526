import styled from 'styled-components'
import {Link} from 'react-scroll'

export const Button = styled(Link)`
border-radius: 30px;
border-color: none;
border-style: solid;
background: ${({primary}) => (primary ? '#5a2c7e' : '#fff')};
white-space: nowrap;
padding: ${({big}) => (big ? '14px 48px' : '12px 30px')};
color: ${({dark}) => (dark ? '#5a2c7e' : '#fff')};
font-size: ${({fontBig}) => (fontBig ? '25px' : '12px')};
font-family: "Open Sans", sans-serif;
outline:none;
border:none;
cursor:pointer;
display:flex;
justify-content:center;
align-items:center;
transition:all 0.3s ease-in-out;


&:hover {
    transition: all 0.3s ease-in-out;
    transition: 250ms;
    background:${({primary}) => (primary ? '#fff' : '#5a2c7e')}

    
}
`;

export const ButtonLabel = styled.label`
font-size: 20px;
color: white;

&:hover {
    transition: all 0.3s ease-in-out;
    transition: 250ms;
    color: black;

    
}



`;


