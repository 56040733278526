import '../App.css';
//import { Button } from './Button';

import './HeroSection.css';


import {motion} from 'framer-motion'
import { Link } from 'react-router-dom';
import {Button, ButtonLabel} from './ButtonElement';
import React, {useState} from 'react';




function HeroSection  () {

/*const fadeTitle = {
  hidden: { opacity: 0, x: -100 },
  visible: { opacity: 1, x: 0}
};*/

const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  }

const containerVariants= {
  hidden: {
    opacity: 0,
    //x: '100vw'
  },
  visible: {
    opacity: 1,
    //x: 0,
    transition: {
      delay: 0.5,
      duration: 0.5,
      //type: 'spring',
      //delay: 0.5,we don't need if we are going to use "mass"
      //mass: 0.4,
      //damping: 8,
      when: "beforeChildren",
      staggerChildren: 0.4
    },
    exit: {
      x: '-100vw',
    transition: { ease: 'easeInOut'}
   


  }

}
}

const childVariants= {
  hidden: {
    opacity: 0
  },
  visible: {
    opacity: 1
  }
  

}




  return (
    <motion.div className='hero-container'
    variants={containerVariants}
    initial="hidden"
    animate="visible"
    exit="exit"
    
    > 
 


   
    
  
     
 
    <motion.h1
variants={childVariants}


    >
HWASA empodera y apoya a todas las mujeres de origen hispano a alcanzar su potencial y mejorar su desarrollo social y profesional 
</motion.h1>




<motion.div className='hero-btns'
variants={childVariants}


 /*initial={{ opacity: 0 }}
 animate={{ opacity: 1, transition: { duration: 1.5 }}}*/
> 
  <div className='hero-btns'>
   <Button  
   className='btns'
   onMouseEnter={onHover}
         onMouseLeave={onHover}
           primary='true'
           dark='true'>
             
             <Link to='/áreas de enfoque'> <ButtonLabel>Áreas de Enfoque</ButtonLabel></Link>
           
           
             
           </Button>
        
  
   
   
           

<Button 
  className='btns'
 
   onMouseEnter={onHover}
         onMouseLeave={onHover}
           primary='true'
           dark='true'>
           <Link to='/contáctanos'> <ButtonLabel>Contáctanos</ButtonLabel> </Link>
           
             
           </Button>

           </div> 
              
   </motion.div>
       
        
 

     
   
      {/*<video src='/videos/video-1.mp4' autoPlay loop muted />*/}
     
      {/*<p>HWASA empowers and supports all women from Hispanic backgrounds to reach their potential and enhance their social and professional development.</p>*/}
     
    </motion.div>
  
  );
  
}


export default HeroSection;
