import React from 'react';
import './Cards3.css';
import Slider from '../components/Slider/Slider'



function Cards3() {


  


  return (

    
  <>


      <Slider  />



    
   </>

   
  

   
  );
}

export default Cards3;


