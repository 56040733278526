
import '../../App.css';
import './Socia.css';
import { Link } from 'react-router-dom';
import {Button, ButtonLabel} from '../ButtonElement';
import React, {useState} from 'react';
import Titleimage from '../Titleimage';
//import {motion} from 'framer-motion';



function Socia() {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);

  }
  
return (
    
<div>
<Titleimage heading='Unete a Hwasa' />

<div className='mision'>
<div className='left'>
  <div className='img-container'>
  <img src='/images/img16.jpg' alt=''className='mision-me' loading='auto'/>  
  </div>
  </div>
<div className='right'>

  <h2>Socia</h2>
  <p>
     Apoyar a las mujeres de origen hispano y sus familias mediante el desarrollo de una variedad de eventos y actividades que satisfagan sus necesidades sociales, culturales, educativas y laborales.
     </p>
    
<div className='socia-btn'>
<Button
        
        className='btns1'
        onMouseEnter={onHover}
       onMouseLeave={onHover}
         primary='true'
         dark='true'
         onClick={{ pathname: "https://forms.gle/ZvkdDE5DWzcUuHD49 " }} // english version
         target='_blank'
         rel='noopener noreferrer' // good security practice 
        
      
     >          
        <Link to={{ pathname: "https://forms.gle/tKVmQZuZ6fDjhpmc8" }} // spanish version
           target='_blank'
           rel='noopener noreferre' // good security practice 
           
>

          <ButtonLabel>Formulario Socia en Ingles</ButtonLabel>   
           </Link>

                  
        
          
          
        </Button>
 
      
 

        <Button
          
          className='btns2'
          onMouseEnter={onHover}
         onMouseLeave={onHover}
           primary='true'
           dark='true'
           onClick={{ pathname: "https://forms.gle/UZ5ZfkarowNwwEfj8" }}  
           target='_blank'
           rel='noopener noreferre' // good security practice
           >
           <Link to={{ pathname: "https://forms.gle/UZ5ZfkarowNwwEfj8" }} // spanish version
            target='_blank'
            rel='noopener noreferre' // good security practice
    
           > <ButtonLabel>Formulario Socia en Español</ButtonLabel> </Link>
          
          
                  
        
          
          
        </Button>
   

</div>

    
    
     </div>
     

  
  </div>
     

    </div>



   
 
 
     

  
  );
}

export default Socia;
