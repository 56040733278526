import '../../App.css';
import './Disfruta.css';
import React from 'react';
import Titleimage from '../Titleimage';
//import {motion} from 'framer-motion';


function Orientación() {
 
  
  return (
    
    <div>
    
<Titleimage heading='Orientación e Información' />
<div className='disfruta'>
<div className='left'>
  <div className='img-container'>
  <img src='/images/img51.jpg' alt=''className='disfruta-me' loading='auto'/>  
  </div>
  </div>
<div className='right'>

  
 
  <h2>Grupo de Apoyo Unidos Lograremos</h2>
       <p>
    
     </p>
     
  </div>
<div className='descripcion'>

     
     <p>Estos talleres son de capacity building, para generar 
    capacidad de conocimientos sobre el tema de las discapacidades en la comunidad hispano hablante.</p>
     <p>Se tendrán diferentes talleres hasta octubre 2022.</p>
     <p>Venga a acompañarnos para romper barreras y escuchar a los profesionales.</p>
     <p>Ustedes son los portavoces de este tema y lo importante 
    es que nuestras familias se enteren de los servicios que hay y que no sufran en silencio.</p>
     <p>Habrá un rico almuerzo Latino y servicio de taxi para aquellos que no pueden llegar debido a su discapacidad.</p>

   
    
</div>
<div className='descripcion-img'>
  <div className='img-container2'>
  <img src='/images/img46.jpg' alt=''className='descripcion-me' loading='auto'/>  
  </div>
    
  
  </div>
  
  </div>

  </div>
     

  
  );
}

export default Orientación;
