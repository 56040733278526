import React from 'react';
import '../../App.css';
import './ContactUs.css';
//import emailjs from 'emailjs-com'
import Titleimage from '../Titleimage';
import Swal from 'sweetalert2'



export default function Contact() {

    const onSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
     
    
        formData.append("access_key", "e7ff0646-b402-4389-a532-a3ce2a008f4e");
    
        const object = Object.fromEntries(formData);
        const json = JSON.stringify(object);
      
    
        const res = await fetch("https://api.web3forms.com/submit", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
          },
          body: json
        }).then((res) => res.json());
    
        if (res.success) {
            Swal.fire({
                title: "Enviado!",
                text: "Mensage enviado exitosamente",
                icon: "success"
              }); 
       
        }




      };



   

    return(
        <div>
               <Titleimage heading='Contacto' text='Nos encanta saber de ti y poderte ayudar en lo que necesites.' />
            <div className="contact">
           {/*<form onSubmit={sendEmail}>*/ } <form onSubmit={onSubmit}>
                    <div className="row pt-5 mx-auto">
                        <div className="col-8 form-group mx-auto">
                            <input type="text" className="form-control" placeholder="Nombre"  name="name" required/>
                        </div>
                        <div className="col-8 form-group pt-2 mx-auto">
                            <input type="email" className="form-control" placeholder="Correo Electrónico" name="email" required/>
                        </div>
                        <div className="col-8 form-group pt-2 mx-auto">
                            <input type="text" className="form-control" placeholder="Asunto" name="subject" required/>
                        </div>
                        <div className="col-8 form-group pt-2 mx-auto">
                            <textarea className="form-control" id="" cols="30" rows="8" placeholder="Mensaje" name="message" required></textarea>
                            <button type="submit">ENVIAR</button>
                        </div>
                        {/*<div className="col-8 pt-3 mx-auto">
                            <input type="submit" className="btn" value="ENVIAR"></input>
                            
                        </div> */}
                    </div>
                </form>
            </div>
        </div>
    )
}
 