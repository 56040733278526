
import '../../App.css';
import './Socia.css';
//import {motion} from 'framer-motion';
import { Link } from 'react-router-dom';
import {Button, ButtonLabel} from '../ButtonElement';
import Titleimage from '../Titleimage';
import React, {useState} from 'react';



function Voluntaria() {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);

  }
  
return (
    
<div>

<Titleimage heading='Unete a Hwasa' /> 
  
<div className='mision'>
<div className='left'>
  <div className='img-container'>
  <img src='/images/img16.jpg' alt=''className='mision-me' loading='auto'/>  
  </div>
  </div>
<div className='right'>

     <h2>Voluntaria</h2>
     <p>
     Apoyar a las mujeres de origen hispano y sus familias mediante el desarrollo de una variedad de eventos y actividades que satisfagan sus necesidades sociales, culturales, educativas y laborales.
     </p>
    
     <Button
        
        className='btns'
        onMouseEnter={onHover}
       onMouseLeave={onHover}
         primary='true'
         dark='true'
         onClick={{ pathname: "https://forms.gle/HBfEyceriA1y5nux9" }}
         target='_blank'
         rel='noopener noreferrer'// good security practice
     >          
           <Link 
           to={{ pathname: "https://forms.gle/HBfEyceriA1y5nux9" }}  //english version only
           target='_blank'
           rel='noopener noreferrer'// good security practice
        
         
           >


          <ButtonLabel>Formulario Voluntaria</ButtonLabel> 

          </Link>
         
          
          
                  
        
          
          
        </Button>
     
      
        
    
  
     </div>
    
     </div>
     

  
  </div>
     

   



   
 
 
     

  
  );
}

export default Voluntaria;
