
import './Cards.css';
//import { Button } from './Button';
import {motion} from 'framer-motion';
import { Link } from 'react-router-dom';
import {Button, ButtonLabel} from './ButtonElement';
import React, {useState} from 'react';



function Cards() {
 
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  }
  return (
    <div className='cards'>
  
  <motion.img src='/images/logo1.png' alt='logo'  className='hwasa-logo' loading='auto'  
  initial={{ opacity: 0 }}
  animate={{ opacity: 1}}
whileHover={{ scale: 1.1 }}
 visible={{ opacity: 1, transition: { duration: 2, type: "tween"}}}

    />     
     <div className='cards-font'
    
     
     >

     <h1>Nuestra Historia</h1>
     <p>Fundada en Julio de 2019 por 10 mujeres de diversos orígenes culturales, se ha convertido en una asociación centrada en las mujeres, sus familias y sus necesidades. Nuestro objetivo final es empoderar a las mujeres mediante la provisión de información y servicios de referencia que sean relevantes para ellas. </p>
     <Button
          
          className='btns'
          onMouseEnter={onHover}
         onMouseLeave={onHover}
           primary='true'
           dark='true'>
           <Link to='/quiénes somos'> <ButtonLabel href="#quiénes somos">aprende más sobre nosotras</ButtonLabel> </Link>
          
          
                  
        
          
          
        </Button>
     <div className='cards-btns'>
  
     </div>
    
     </div>
     

  
  </div>
     
 
 
     

  
  );
}

export default Cards;
