import React from 'react';
import './Footer.css';

import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className='footer-container'>
      <div className='container'>
      <div className='row'>
    {/*Column1 */}
    <div className='col'>
      <h4>Hispanic Women's Association of South Australia</h4>



    </div>
     {/*Column2 */}
  <div className='col'>
      
<ul className='list-unstyled'>
<li>MCCSA - 113 Gilbert Street,</li>
<li>Adelaide, South Australia, 5000</li>
<li>Australia</li>
<li>Telephone: 0491 090 991</li>
<li>Email: hwasa.adelaide@gmail.com </li>
<li>Abierto todos los Viernes 10am - 2pm </li>

</ul>


    </div>
    
  {/*Column3 */}

  
  <div className='col'>
      
<ul className='footer-links'>

<Link to='/contáctanos'>Contáctanos</Link>
<Link to='/'>Política de privacidad</Link>
<Link   class='social-icon-link facebook'
              to={{ pathname: "https://www.facebook.com/HWAofSA" }}
              target='_blank'
              rel='noopener' // good security practice
              aria-label='Facebook'>¡Síguenos en Facebook! <i class='fab fa-facebook' /></Link>

</ul>


    </div>
      </div>
      <hr />
      <div className='row'>
        <p className='col-sm'>
          &copy;{new Date().getFullYear()} Hispanic Women's Association of South Australia 
         <p  className='col-sm'>
         Web Design by <a target="_blank" rel='noopener noreferrer' href={'https://www.linkedin.com/in/angelarojasbotina/'}>Angela R </a> 
           </p> 
        </p>
      </div>
   
      </div>
    
      </div>
      
  );
}

export default Footer;
