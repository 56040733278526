import React from 'react';
import { Link } from 'react-router-dom';
import './Cards4.css';





function Cards4() {
  return (
    <div className='cards4'>
      
      <h1>AGENCIAS DE APOYO</h1>
      <p>HWASA aprecia el apoyo de las siguientes instituciones</p>

<div className='logo'>

<Link
className='logo'
to={{ pathname: "https://mccsa.org.au/" }}
              target='_blank'
              rel='noopener' // good security practice
              aria-label='Mccsa'
              >

<img src='/images/MCCSA-Logo.png' alt='' className='logo'loading='lazy' />

  
</Link>  


<Link
className='logo'
to={{ pathname: "https://purpleorange.org.au/" }}
              target='_blank'
              rel='noopener' // good security practice
              aria-label='Purple Orange'
              >

<img src='/images/orange-logo.png' alt=''className='logo' loading='lazy'/>

  
</Link>   


</div>
  
 
     

      



</div>

    
   

     







  
  );
}

export default Cards4;
