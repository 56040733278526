
import React from 'react';
import { Link } from 'react-router-dom';




function CardItem1(props) {


  
  return (
    <>
      <li className='cards1__item'>
        <Link className='cards1__item__link' to={props.path}>
          <figure className='cards1__item__pic-wrap' data-category={props.label}>
            <img
              className='cards1__item__img'
              alt='Travel'
              src={props.src}
            />
     
           
          </figure>
          <div className='cards1__item__info'>
        
         <p className='cards1__item__text'>{props.text}</p>
         
        

  



     



          </div>

        
       
        </Link>
      </li>



      




    </>
  );
}

export default CardItem1;
