import '../../App.css';
import './Disfruta.css';
import React from 'react';
import Titleimage from '../Titleimage';
//import {motion} from 'framer-motion';


function Familia() {
 
  
  return (
    
    <div>
    
<Titleimage heading='La Familia Hispana' />
<div className='disfruta'>
<div className='left'>
  <div className='img-container'>
  <img src='/images/img35.jpg' alt=''className='disfruta-me' loading='auto'/>  
  </div>
  </div>
<div className='right'>

  
 
  <h2>Objetivo</h2>
  <p>Grupo Intergeneracional Hispanohablante</p>
     <p>
     Este grupo tiene como objetivo asistir a padres, abuelos, tíos en la crianza de los niños en Australia mediante una variedad de actividades como: baile, canto, grupos de juegos, salidas al campo, celebraciones, cuenta cuentos y brindando 
     información en el embarazo, crianza, desarrollo del niño, educación, alimentación entre otros temas.
     </p>
     
  </div>
<div className='descripcion'>

     <h2>Horarios</h2>
     <p>Este grupo se reúne en: </p>
     <p>Para Hills Community Hub </p>
     <p>22 Wilkinson Road Para Hills</p>
     <p>El 1er y 3er jueves de cada mes de 10am a 1pm. Costo $3</p>
     <p>Marion Church of Christ</p>
     <p>Corner of Marion Road y Alwoona ave Mitchell Park </p>
     <p>El 2do y 4to miércoles de cada mes de 10am a 1pm. Costo $3</p>

   
    
</div>
<div className='descripcion-img'>
  <div className='img-container2'>
  <img src='/images/img36.jpg' alt=''className='descripcion-me' loading='auto'/>  
  </div>
    
  
  </div>
  
  </div>

  </div>
     

  
  );
}

export default Familia;
