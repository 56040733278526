import '../../App.css';
import './Laborales.css';
import React from 'react';
import Titleimage from '../Titleimage';
//import {motion} from 'framer-motion';


function Laborales () {
 
  
  return (
    
    <div>
    
<Titleimage heading='Oportunidades Laborales en SA' />
<div className='oportunidades'>
<div className='left'>
  <div className='img-container'>
  <img src='/images/img52.jpg' alt=''className='oportunidades-me' loading='auto'/>  
  </div>
  </div>
<div className='right'>

  
 
  <h2>Objectivo</h2>
       <p>
       Apoyar a las personas que buscan trabajo por primera 
       vez y a las personas que buscan una nueva carrera profesional para estar listas para trabajar en SA
     </p>
     
  </div>
<div className='descripcion'>

<h2>Descripción</h2>     
     <p>¿Estás buscando tu primer trabajo aquí en Adelaida?</p>
     <p>¿Quiere cambiarse a un nuevo trabajo, actualizar 
         sus documentos personales y prepararse para el proceso de búsqueda de trabajo?</p>
     <p>Entendemos que buscar trabajo en Adelaida y prepararse para comenzar a trabajar puede ser un desafío. Por lo que la Asociación de Mujeres Hispanas de Australia del Sur - HWASA quiere apoyarte para maximizar tu tiempo, investigación, y 
         preparación y así aumentar tus posibilidades de ser contratado en el trabajo de sus sueños</p>
    
   
    
</div>
<div className='descripcion-img'>
  <div className='img-container2'>
  <img src='/images/img40.jpg' alt=''className='descripcion-me' loading='auto'/>  
  </div>
    
  
  </div>
  
  </div>

  </div>
     

  
  );
}

export default Laborales;
