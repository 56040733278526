
import '../../App.css';
import './Disfruta.css';
import React from 'react';
import Titleimage from '../Titleimage';
//import {motion} from 'framer-motion';


function Disfruta() {
 
  
  return (
    
    <div>
    
<Titleimage heading='Disfruta en Línea' />
<div className='disfruta'>
<div className='left'>
  <div className='img-container'>
  <img src='/images/img30.jpg' alt=''className='disfruta-me' loading='auto'/>  
  </div>
  </div>
<div className='right'>

  <h2>Objetivo</h2>
     <p>
     Crear, compartir y valorar un espacio seguro en el cual las mujeres 
     de habla hispana se reúnan encontrando apoyo, empatía y sororidad ya sea vía online o presencial.
     </p>
     
  </div>
<div className='descripcion'>

     <h2>Descripción</h2>
     <p>
     Esta actividad nace con la intención de disfrutar de eventos los cuales favorezcan e incrementen el desarrollo y conexión interior de las mujeres empoderándolas tanto a ellas como cada participante de dichas actividades. Estos talleres se realizan finales de cada mes, son eventos relacionados 
     con el compañerismo, fuerza y motivación para la comunidad latina en especial mujeres de habla hispana. 
     </p>
    
</div>
<div className='descripcion-img'>
  <div className='img-container2'>
  <img src='/images/img37.jpg' alt=''className='descripcion-me' loading='auto'/>  
  </div>
    
  
  </div>
  
  </div>

  </div>
     

  
  );
}

export default Disfruta;
