import '../../App.css';
import './Events.css';
import './Socia.css';
import { Link } from 'react-router-dom';
import {Button, ButtonLabel} from '../ButtonElement';
import React, {useState} from 'react';
import Titleimage from '../Titleimage';



function Boletin() {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  
  }
  return (
    <div>
   <Titleimage heading='Boletin Informativo' />
  
<div className='mision'>
<div className='left'>
  <div className='img-container'>
  <img src='/images/img72.JPG' alt=''className='mision-me' loading='auto'/>  
  </div>
  </div>
<div className='right'>

  <h2>Nuestra Ultima Edición</h2>
  <p>
     Disfruta de nuestro boletin informativo Primavera 2024
     </p>
    
<div className='socia-btn'>
  <div className='right'>
<Button
        
        className='btns1'
        onMouseEnter={onHover}
       onMouseLeave={onHover}
         primary='true'
         dark='true'
         onClick={{ pathname: "https://issuu.com/angelarojasb/docs/hwasa_newsletter_spring_2024_edition-f" }} 
         target='_blank'
         rel='noopener noreferrer' // good security practice 
         
        
      
     >          
        <Link to={{ pathname: "https://issuu.com/angelarojasb/docs/hwasa_newsletter_spring_2024_edition-f" }} 
           target='_blank'
           rel='noopener noreferre' // good security practice 
           
>


          <ButtonLabel>Lee aquí el contenido completo</ButtonLabel>   
           </Link>

                          
      
          
          
        </Button>
 
  <p>
     Ediciones Pasadas
     </p>
<ul>
<li>
   <a href= "https://issuu.com/angelarojasb/docs/hwasa_newsletter_autumn_2024_edition_final">
   Edición Otoño 2024
   </a>
   </li>
   <li>
   <a href= "https://issuu.com/angelarojasb/docs/hwasa_newsletter_summer_2024_edition">
   Edición Verano 2023 - 24
   </a>
   </li>
   <li>
   <a href= "https://issuu.com/angelarojasb/docs/hwasa_newsletter_autumn_2023_edition">
   Edición Otoño 2023
   </a>
   </li>
   <li>
   <a href= "https://issuu.com/angelarojasb/docs/hwasa_newsletter_summer_2022-23_edition">
   Edición Verano 2022 - 23
   </a>
   </li>
  


</ul>


{/* 

        <Button
        
        className='btns1'
        onMouseEnter={onHover}
       onMouseLeave={onHover}
         primary='true'
         dark='true'
         onClick={{ pathname: "https://issuu.com/angelarojasb/docs/hwasa_newsletter_summer_2024_edition" }} 
         target='_blank'
         rel='noopener noreferrer' // good security practice 
        
      
     >          
        <Link to={{ pathname: "https://issuu.com/angelarojasb/docs/hwasa_newsletter_summer_2024_edition" }} 
           target='_blank'
           rel='noopener noreferre' // good security practice 
           
>


          <ButtonLabel>Edición Verano 2023 - 24</ButtonLabel>   
           </Link>

                          
        
          
          
        </Button>

<Button
        
        className='btns1'
        onMouseEnter={onHover}
       onMouseLeave={onHover}
         primary='true'
         dark='true'
         onClick={{ pathname: "https://issuu.com/angelarojasb/docs/hwasa_newsletter_autumn_2023_edition" }} 
         target='_blank'
         rel='noopener noreferrer' // good security practice 
        
      
     >          
        <Link to={{ pathname: "https://issuu.com/angelarojasb/docs/hwasa_newsletter_autumn_2023_edition" }} 
           target='_blank'
           rel='noopener noreferre' // good security practice 
           
>


          <ButtonLabel>Edición Otoño 2023</ButtonLabel>   
           </Link>

                          
        
          
          
        </Button>

        <Button
        
        className='btns1'
        onMouseEnter={onHover}
       onMouseLeave={onHover}
         primary='true'
         dark='true'
         onClick={{ pathname: "https://issuu.com/angelarojasb/docs/hwasa_newsletter_summer_2022-23_edition" }} 
         target='_blank'
         rel='noopener noreferrer' // good security practice 
        
      
     >          
        <Link to={{ pathname: "https://issuu.com/angelarojasb/docs/hwasa_newsletter_summer_2022-23_edition" }} 
           target='_blank'
           rel='noopener noreferre' // good security practice 
           
>


          <ButtonLabel>Edición Verano 2022 - 23</ButtonLabel>   
           </Link>

                  
        
          
          
        </Button>
 
        <Button
        
        className='btns1'
        onMouseEnter={onHover}
       onMouseLeave={onHover}
         primary='true'
         dark='true'
         onClick={{ pathname: "https://issuu.com/angelarojasb/docs/hwasa_newsletter_autumn_2022_edition_f" }} 
         target='_blank'
         rel='noopener noreferrer' // good security practice 
        
      
     >          
        <Link to={{ pathname: "https://issuu.com/angelarojasb/docs/hwasa_newsletter_autumn_2022_edition_f" }} 
           target='_blank'
           rel='noopener noreferre' // good security practice 
           
>


          <ButtonLabel>Edición Otoño 2022</ButtonLabel>   
           </Link>

                  
        
          
          
        </Button>
        */}
 
        </div>

</div>

    
    
     </div>
     

  
  </div>
     

    </div>



   
 
 
     

  
  );
}


export default Boletin;

